1<template>
    <div>
        <h2>Захоронение</h2>
        <el-row>
            <el-col :span="4">
                <el-date-picker
                style="width: 100%"
                type="year"
                value-format="yyyy"
                placeholder="Год"
                v-model="years"
            >
            </el-date-picker>
            </el-col>
            <el-col :span="4">
                <el-button type="success" @click="reload()">
                    Обновить
                </el-button>
            </el-col>
        </el-row>

        <el-divider></el-divider>

        <el-table stripe :data="limits">
            
            <el-table-column label="Код отхода" prop="waste_code" width="80"></el-table-column>
            <el-table-column label="Наименование отхода" prop="waste_name"></el-table-column>
            <el-table-column label="Степень опасности" prop="danger_lvl_name" width="100"></el-table-column>
            <el-table-column label="Количество отходов, направляемое на захоронение в год" prop="k">
                <!-- eslint-disable -->
                <template slot-scope="item">
                    {{ item.row.k + " " + item.row.pss93UnitsInfo}}
                    <br>
                    {{ ((new Date(item.row.date_to).getFullYear() == year && (new Date(item.row.date_to) - prev_dt) != 31449600000) || (new Date(item.row.date_from).getFullYear() == year && next_dt - new Date(item.row.date_from) != 31449600000) ? 
                        ("В указанном году: " + 
                        Math.round((
                                new Date(item.row.date_to).getFullYear() == year && new Date(item.row.date_from).getFullYear() == year ? 
                                (Math.floor((new Date(item.row.date_to) - new Date(item.row.date_from)) / (1000 * 60 * 60 * 24))* item.row.k/364)  :
                                (
                                    new Date(item.row.date_from).getFullYear() == year ? Math.floor((next_dt - new Date(item.row.date_from)) / (1000 * 60 * 60 * 24))* item.row.k/364  : ""
                                    +
                                    new Date(item.row.date_to).getFullYear() == year ? Math.floor((new Date(item.row.date_to) - prev_dt) / (1000 * 60 * 60 * 24))* item.row.k/364  : ""
                                )
                            )*10000)/10000 + " " + item.row.pss93UnitsInfo
                        )  : "") }}
                </template>
                <!-- eslint-enable -->
            </el-table-column>
            <el-table-column label="Период действия лимита" prop="">
                
                <!-- eslint-disable -->
                <template slot-scope="item">
                    {{ 
                        (item.row.date_from!=null ?
                        (""+((new Date(item.row.date_from).getDate()<10?'0':'') + new Date(item.row.date_from).getDate()+"."+(new Date(item.row.date_from).getMonth()<9?'0':'')+(new Date(item.row.date_from).getMonth()+1)+"."+new Date(item.row.date_from).getFullYear()) )
                        : (item.row.date_to!=null ? "" : ""))
                        +"-"
                        +(item.row.date_to!=null ?
                        (((new Date(item.row.date_to).getDate()<10?'0':'') + new Date(item.row.date_to).getDate()+"."+(new Date(item.row.date_to).getMonth()<9?'0':'')+(new Date(item.row.date_to).getMonth()+1)+"."+new Date(item.row.date_to).getFullYear())+"" )
                        : (item.row.date_from!=null ? "" : ""))
                    }}
                </template>
                
                <!-- eslint-enable -->
            </el-table-column>
            <el-table-column label="Привязка" prop="">
                <template slot-scope="item">
                    {{ item.index }}
                    <el-tree
                        :data="item.row.orgsList"
                        show-checkbox
                        node-key="id"
                        :ref="'trees'+item.row.id"
                        :props="defaultProps"
                        style="font-weight: bold;"
                        >
                    </el-tree>
                </template>
            </el-table-column>
            <el-table-column label="Фактически направлено на захоронение" prop="">
                <template slot-scope="item">
                    {{ Math.round(item.row.st*1000)/1000 + " " + item.row.pss93UnitsInfo }}
                </template>
            </el-table-column>
            <el-table-column label="" prop="">
                <template slot-scope="item">
                    <el-progress  :percentage="Math.round(item.row.st*1000/(item.row.k*
                    (
                        new Date(item.row.date_to).getFullYear() == year && new Date(item.row.date_from).getFullYear() == year ? Math.floor((new Date(item.row.date_to) - new Date(item.row.date_from)) / (1000 * 60 * 60 * 24))/364 :
                        (
                            new Date(item.row.date_from).getFullYear() == year ? Math.floor((next_dt - new Date(item.row.date_from)) / (1000 * 60 * 60 * 24))/364 : 
                            (
                                new Date(item.row.date_to).getFullYear() == year ? Math.floor((new Date(item.row.date_to) - prev_dt) / (1000 * 60 * 60 * 24))/364 : 1
                            )
                        )
                    )
                    ))/10" :color="customColorMethod"></el-progress>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
import { AXIOS } from "@/AXIOS/http-common";
import Cookies from "js-cookie";

export default {
  name: "limitsReserch3",
  data() {
    return {
        prev_dt:null,
        next_dt:null,
        year:null,
        years:null,
      prod:0,
        limits:[],
        orgsList:[],
        wastes:[{id:null,organizationInfo:{name:"Объект к которому прявязан отход"},ss01WasteDictionaryDTO:{wasteName:"Отход",code:"Код отхода"}}],
        search:{type:null,},
        limitstype: [
            {id:1,name:"Транспортная единица"},
            {id:2,name:"Разрешение на захоронение"},
            {id:3,name:"Разрешение на хранение"},
            {id:4,name:"Норматив образования отхода"}
        ],
        defaultProps: {
        children: "children",
        label: "name",
        },
    };
  },
  methods: {
    customColorMethod(percentage) {
        if (percentage < 100) {
          return '#409eff';
        } else if (percentage == 100) {
          return '#67c23a';
        } else {
          return '#FF0000';
        }
      },
    async reload()
    {
        this.year=parseInt(this.years)+"";
        this.prev_dt = new Date(parseInt(this.year), 0, 1);
        this.next_dt = new Date(parseInt(this.year)+1, 0, 0);
        
        await AXIOS.get(
            "user/organization/hierarchyOrgByWaste?waste=" + 0
        ).then(async (response) => {
            this.orgsList=response.data;
            await AXIOS.post("Limits/getReserch",{year:parseInt(this.year),type:2}).then( (response) => {
                response.data.forEach((el)=>{
                    el.date_from=el.date_from!=null?new Date(el.date_from):null;
                    el.date_to=el.date_to!=null?new Date(el.date_to):null;
                });
                this.limits=response.data;
                this.limits.forEach( (limit)=>{
                    limit.orgsList= this.orgsl();
                    this.$nextTick(() => {
                        let orgChekId=[];
                        limit.limitsOrg.forEach(limitsOr => {
                            orgChekId.push(limitsOr.organization_id);
                        });
                        console.log(orgChekId);
                        console.log(this.$refs["trees"+limit.id]);
                        console.log('this',this);
                        this.$refs["trees"+limit.id].setCheckedKeys(orgChekId);
                });
                });
                /*this.limits=this.limits.filter((lim) => lim.st > 0);*/
            });
        });
    },
    

    orgsl()
    {
        let orgsl=[{id: this.orgsList[0].id,name: this.orgsList[0].name,children: [],disabled: true}];
        this.orgsList.forEach(element => {
            if(element.parent == orgsl[0].id)
                orgsl[0].children.push({id:element.id,name:element.name,children: [],disabled: true});
        });
        orgsl[0].children.forEach(child => {
            this.orgsList.forEach(element => {
                if(element.parent == child.id)
                child.children.push({id:element.id,name:element.name,children: [],disabled: true});
            });
            child.children.forEach(child2 => {
                this.orgsList.forEach(element => {
                    if(element.parent == child2.id)
                    child2.children.push({id:element.id,name:element.name,children: [],disabled: true});
                });
            });
        });
        return orgsl;
    },
    create()
    {
        this.limits.push({id:null,orgsList:this.orgsl()});
    }
  },
  async mounted() {
    console.log("Cookies.get('prod'):"+Cookies.get('prod'));
    console.log("Cookies:"+Cookies);
    this.prod = Cookies.get('prod');
    /*await AXIOS.get("organization-waste/list?page=" + 0 + "&size="+10000).then(
      (response) => {
        console.log("response.data");
        console.log(response.data.content);
        this.wastes=response.data.content.sort((a, b) => {
  const nameA = a.organizationInfo.name.toUpperCase()+a.ss01WasteDictionaryDTO.wasteName.toUpperCase(); // ignore upper and lowercase
  const nameB = b.organizationInfo.name.toUpperCase()+b.ss01WasteDictionaryDTO.wasteName.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
        });
      }
    );*/
    
  },
}
</script>